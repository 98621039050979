<template>
  <div>
    <v-row>
      <v-col cols="12" md="6">
        <v-card md12 xs12 class="pa-2 mt-6">
          <h3 class="title">
            Jetzt als Tierarzt registrieren
            <sup class="green--text">kostenlos</sup>
          </h3>

          <v-form
            ref="insertForm"
            v-if="!insertErfolg"
            @submit.prevent="submitUserInsert"
            class="pl-2"
          >
            <v-layout class="mt-4">
              <v-flex xs12>
                <v-text-field
                  v-model="userdata.user_name"
                  background-color="white"
                  type="text"
                  label="Praxis-Name"
                  :rules="[checkTextFeld('user_name')]"
                  :append-icon="getIconSuccessText('user_name')"
                />
              </v-flex>
            </v-layout>

            <v-layout class="mt-4">
              <v-flex xs12>
                <v-text-field
                  v-model="userdata.user_email"
                  background-color="white"
                  type="text"
                  label="Ihre E-Mail"
                  :rules="[validateEmail(this.userdata.user_email)]"
                  :append-icon="getIconSuccess()"
                />
              </v-flex>
            </v-layout>

            <v-layout class="mt-4">
              <v-flex xs8>
                <v-text-field
                  v-model="userdata.user_strasse"
                  background-color="white"
                  type="text"
                  label="Ihre Strasse"
                  :rules="[checkTextFeld('user_strasse')]"
                  :append-icon="getIconSuccessText('user_strasse')"
                />
              </v-flex>
              <v-flex xs4 ml-4>
                <v-text-field
                  v-model="userdata.user_hsnr"
                  background-color="white"
                  type="text"
                  label="Hausnummer"
                />
              </v-flex>
            </v-layout>

            <v-layout class="mt-4">
              <v-flex xs4>
                <v-text-field
                  v-model="userdata.user_plz"
                  background-color="white"
                  type="text"
                  label="PLZ"
                  :rules="[checkTextFeld('user_plz')]"
                  :append-icon="getIconSuccessText('user_plz')"
                />
              </v-flex>
              <v-flex xs8 ml-2>
                <v-text-field
                  v-model="userdata.user_ort"
                  background-color="white"
                  type="text"
                  label="Ort"
                  :rules="[checkTextFeld('user_ort')]"
                  :append-icon="getIconSuccessText('user_ort')"
                />
              </v-flex>
            </v-layout>
            <!-- <v-layout class="mt-4">
              <v-flex xs12>
                <v-text-field
                  v-model="userdata.user_url"
                  background-color="white"
                  type="text"
                  label="Ihre Webseite *"
                />
              </v-flex>
            </v-layout> -->

            <v-layout class="mt-4">
              <v-flex xs12>
                <v-text-field
                  style="max-width: 280px"
                  v-model="userdata.user_pass_neu1"
                  background-color="white"
                  :rules="[checkTextFeld('user_pass_neu1')]"
                  :type="showPwd1 ? 'password' : 'text'"
                  label="Ihr  Passwort"
                  autocomplete="on"
                  :append-icon="showPwd1 ? 'visibility' : 'visibility_off'"
                  @click:append="showPwd1 = !showPwd1"
                />
              </v-flex>
            </v-layout>

            <v-layout class="mt-4">
              <v-flex xs12>
                <v-text-field
                  style="max-width: 280px"
                  v-model="userdata.user_pass_neu2"
                  background-color="white"
                  :rules="[checkTextFeld('user_pass_neu2')]"
                  :type="showPwd2 ? 'password' : 'text'"
                  label="Wiederholen Sie Ihr  Passwort"
                  autocomplete="on"
                  :append-icon="showPwd2 ? 'visibility' : 'visibility_off'"
                  @click:append="showPwd2 = !showPwd2"
                />
              </v-flex>
            </v-layout>

            <v-layout class="mt-2" justify="start">
              <v-flex xs12>
                <v-btn
                  type="submit"
                  tile
                  color="#d84315"
                  class="button_caps"
                  style="color: white"
                  >Jetzt als Tierarzt registrieren</v-btn
                >
              </v-flex>
            </v-layout>

            <v-layout v-if="error_step2" class="mt-2">
              <v-flex xs12>
                <v-alert
                  border="top"
                  colored-border
                  type="error"
                  elevation="2"
                  dismissible
                >
                  <h3 class="subtitle red--text">
                    Achtung bei der Übertragung kam es zu einem Fehler
                  </h3>
                  {{ error_step2_text }}
                </v-alert>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card>
      </v-col>
      <v-col cols="12" md="3">
        <div
          v-for="stimme in stimmen"
          :key="stimme.stim_id"
          class="pl-4 my-8"
          style="border-bottom:2px solid #fff;background-color:#f9f9f9"
        >
          <v-row>
            <v-col cols="12" md="10" style="vertical-align:top;">
              <h3>
                {{ stimme.stim_title }}
              </h3></v-col
            >
            <v-col cols="12" md="2">
              <span v-if="stimme.stim_type == 'Facebook'">
                <v-icon style="font-size: 30px" class="blue--text"
                  >mdi-facebook</v-icon
                >
              </span>
              <span v-if="stimme.stim_type == 'Email'">
                <v-icon style="font-size: 30px" class="grey--text"
                  >mdi-account</v-icon
                >
              </span>
              <span v-if="stimme.stim_type == 'Presse'">
                <span v-if="stimme.stim_image == ''">
                  <v-icon style="font-size: 30px" class="grey--text"
                    >mdi-mail</v-icon
                  >
                </span>
                <v-list-item-avatar
                  tile
                  size="40"
                  v-if="stimme.stim_image != ''"
                  ><img
                    :src="
                      `https://www.eisbaumtabelle.de/images/${stimme.stim_image}`
                    "
                /></v-list-item-avatar>
              </span>
            </v-col>
          </v-row>

          <em class="pa-2" v-if="!stimme.showalle">
            "{{ stimme.text }}...<a @click="stimme.showalle = !stimme.showalle">
              " lesen....</a
            >
          </em>
          <em class="pa-2" v-if="stimme.showalle">
            "{{ stimme.stim_text }}""
          </em>

          <br /><b>{{ stimme.stim_name }}</b> | {{ stimme.date }}
          <span v-if="stimme.stim_type == 'Presse'">
            |
            <a :href="`${stimme.stim_quelle}`" target="_blank"
              >Veröffentlichung anzeigen</a
            ><br />
          </span>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import RegexHelper from "../../RegexHelper";

  export default {
    name: "register-user",
    components: {},
    data: function() {
      return {
        stimmen: false,
        user: false,
        insertErfolg: false,
        insertSubmitted: false,
        error_step2: false,
        error_step2_text: false,
        showPwd1: "text",
        showPwd2: "text",
        logindata: false,
        userdata: {
          user_name: "",
          user_email: "",
          user_strasse: "",
          user_hsnr: "",
          user_plz: "",
          user_ort: "",
          user_pass_neu1: "",
          user_pass_neu2: "",
        },
      };
    },
    props: [],

    created() {
      this.getStimmen();
    },
    computed: {},
    methods: {
      getStimmen() {
        this.$store
          .dispatch("getStimmen")
          .then((resp) => {
            if (resp.data.stimmen) {
              this.stimmen = resp.data.stimmen;
              document.dispatchEvent(new Event("x-app-rendered"));
            }
          })
          .catch((err) => {
            this.$log.error(err);
          });
      },
      checkForm() {
        let checkPassed = true;
        if (this.userdata.user_pass_neu1 != this.userdata.user_pass_neu2) {
          this.error_step2 = true;
          this.error_step2_text =
            "Ihre beiden Passwörter stimmen nicht überein.";
          //this.$store.dispatch("insertError", 11);
          checkPassed = false;
        }

        if (!this.$refs.insertForm.validate()) {
          //  this.$store.dispatch("insertError", 12);
          checkPassed = false;
          this.$log.debug("checkform");
        }

        return checkPassed;
      },

      checkTextFeld(feld) {
        var showError = false;
        var TextError = "";

        if (feld == "user_name" && this.userdata.user_name == "") {
          TextError = "Bitte geben Sie Ihren Namen an. ";
          showError = true;
        }

        if (feld == "user_strasse" && this.userdata.user_strasse == "") {
          TextError = "Bitte geben Sie Ihre Strasse an. ";
          showError = true;
        }

        if (feld == "user_plz" && this.userdata.user_plz == "") {
          TextError = "Bitte geben Sie Ihre PLZ an. ";
          showError = true;
        }

        if (feld == "user_ort" && this.userdata.user_ort == "") {
          TextError = "Bitte geben Sie Ihre Ort an. ";
          showError = true;
        }

        if (feld == "user_pass_neu1" && this.userdata.user_pass_neu1 == "") {
          TextError = "Bitte geben Sie Ihr neues Passwort  an. ";
          showError = true;
        }

        if (feld == "user_pass_neu2" && this.userdata.user_pass_neu2 == "") {
          TextError = "Bitte wiederholen Sie Ihr neues Passwort. ";
          showError = true;
        }

        if (showError == true) {
          return TextError;
        } else {
          return true;
        }
      },
      validateEmail(email) {
        return RegexHelper.validateEmail(email);
      },
      getIconSuccess() {
        let check = this.validateEmail(this.userdata.user_email);
        if (check === true) {
          return "done";
        } else {
          return "";
        }
      },

      getIconSuccessText(feld) {
        let check = this.checkTextFeld(feld);
        if (check === true) {
          return "done";
        } else {
          return "";
        }
      },

      submitUserInsert() {
        this.$log.debug("submit 1");
        this.error_step2_text = "";
        this.error_step2 = false;

        if (!this.checkForm()) {
          this.$log.debug("!checkform");
          return false;
        }

        this.userdata.action = "insertuser";
        this.$store
          .dispatch("apiUserPost", this.userdata)
          .then((resp) => {
            this.$log.debug("submit 2");
            if (resp.data.error == true) {
              this.error_step2_text =
                "Es existiert bereits ein User mit dieser E-Mail";
              this.error_step2 = resp.data.error;
            } else {
              this.$log.debug("Response insert", resp.data);
              if (resp.data.user) {
                this.$store.commit("user", resp.data.user);
                this.insertErfolg = true;
                this.$log.debug("User ", resp.data);

                window.location.href = "/account";
              } else {
                this.error_step2_text =
                  "Fehler beim Speichern - bitte versuchen Sie es noch einmal.";
                this.error_step2 = true;
                //this.$store.dispatch("insertError", 13);
              }
            }
          })
          .catch((err) => {
            this.$log.error(err);
            // this.errors.push(err);
          });
      },
    },
  };
</script>

<style scoped>
  .button_caps {
    text-transform: none !important;
  }
</style>
