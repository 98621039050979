export default class RegexHelper {
    static validateEmail(email) {
        var showError = false;
        var TextError = "";
        // var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        // @Rusi -> nicht getestet -> getht nicht
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!re.test(String(email).toLowerCase())) {
            TextError =
                "Bitte geben Sie eine gültige E-Mail an. Sonst können wir Ihnen Ihre Anfrage nicht bearbeiten.";
            showError = true;
        }

        if (showError == true) {
            return TextError;
        } else {
            return true;
        }
    }
}
